import amendOrder from 'api/definitions/amendOrder';

import { amendActionComplete } from 'redux/modules/amend-order/actions/amend-action-complete';
import { showAmendOrderErrorModal } from 'redux/modules/amend-order/actions/show-amend-order-error-modal';
import { CANCEL_AMEND } from 'redux/modules/amend-order/actions/types';
import getOrder from 'redux/modules/order/actions/get-order';
import { refreshShoppingContext } from 'redux/modules/sessions/refresh-shopping-context';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';

export const cancelAmendOrder = () => (dispatch, getState) => {
  const customerOrderId = getCustomerOrderId(getState());

  return dispatch({
    apiCall: amendOrder.cancel({ orderIdToAmend: customerOrderId }),
    types: CANCEL_AMEND.triplet,
  })
    .then(({ errors = [], data = {} } = {}) => {
      const failures = data.cancelAmendOrder?.failures ?? [];

      if (errors.length > 0 || data.cancelAmendOrder === undefined || failures.length > 0) {
        const error =
          failures.length || errors.length
            ? JSON.stringify([...failures, ...errors])
            : 'Unknown cancelAmendOrder error';

        return dispatch(showAmendOrderErrorModal(error));
      }

      return Promise.all([
        dispatch(getOrder({ customerOrderId })),
        refreshShoppingContext(dispatch, getState),
      ]);
    })
    .finally(() => dispatch(amendActionComplete()));
};
