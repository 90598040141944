import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './SkipLink.scss';

const SkipLink = memo(({ destination, children }) => (
  <a className={styles.skipLink} data-testid="skipLink" href={destination}>
    {children}
  </a>
));

export default SkipLink;

SkipLink.propTypes = {
  children: PropTypes.node,
  destination: PropTypes.string.isRequired,
};

SkipLink.defaultProps = {
  children: null,
};

SkipLink.displayName = 'SkipLink';
