import { createSelector } from 'reselect';
import { getFavourites, isFavouritesSuccessfullyLoaded } from './get-favourites';

const EMPTY_FAVOURITES_PRODUCTS_ARRAY: never[] = [];

const getFavouritesLegacy = (state: WtrState) => state.favourites;

export const getFavouritesProductsLegacy = createSelector(
  [getFavouritesLegacy],
  favourites => favourites.products || EMPTY_FAVOURITES_PRODUCTS_ARRAY,
);

export const getFavouritesProducts = createSelector([getFavourites], favourites =>
  isFavouritesSuccessfullyLoaded(favourites)
    ? favourites.componentsAndProducts
    : EMPTY_FAVOURITES_PRODUCTS_ARRAY,
);
