import { axios } from 'utils/request';
import { consoleError } from 'utils/logging';
import { MONETATE_DECISIONS_URL } from 'constants/monetate';
import { log } from 'utils/error-logging';
import includeExternal from 'utils/include-external';

export const citrusAdImpressions = async (sponsorshipId: unknown) => {
  if (includeExternal()) {
    try {
      await axios.get('https://wtr-digital-analytics.ew.r.appspot.com/citrusad/impression', {
        params: { adid: sponsorshipId },
      });
    } catch (err) {
      log(err, { logger: 'citrusAdImpressions', section: 'citrusAd' });
    }
  }
};

export const citrusAdClick = async (sponsorshipId: unknown) => {
  if (includeExternal()) {
    try {
      await axios.get('https://wtr-digital-analytics.ew.r.appspot.com/citrusad/click', {
        params: { adid: sponsorshipId },
      });
    } catch (err) {
      log(err, { logger: 'citrusAdClick', section: 'citrusAd' });
    }
  }
};

export const MONETATE_WAITROSE_CHANNEL = 'a-18da6672/p/waitrose.com';

const postMonetateEvents = async (monetateId: unknown, events: unknown) => {
  try {
    await axios.post(MONETATE_DECISIONS_URL, {
      channel: MONETATE_WAITROSE_CHANNEL,
      monetateId,
      events,
    });
  } catch (err) {
    log(err, { logger: 'postMonetateEvents', section: 'monetate' });
  }
};

interface AdMetadata {
  monetateId?: unknown;
  recToken?: unknown;
}

export const metadataAdImpressions = (metadata: AdMetadata) => {
  if (includeExternal() && metadata.monetateId && metadata.recToken) {
    return postMonetateEvents(metadata.monetateId, [
      { eventType: 'monetate:record:RecImpressions', recImpressions: [metadata.recToken] },
    ]);
  }

  return Promise.resolve();
};

export const metadataAdClick = (metadata: AdMetadata) => {
  if (includeExternal() && metadata.monetateId && metadata.recToken) {
    return postMonetateEvents(metadata.monetateId, [
      { eventType: 'monetate:record:RecClicks', recClicks: [metadata.recToken] },
    ]);
  }

  return Promise.resolve();
};

export const citrusAdTransactionCompleted = async (transactionCompleteData: unknown) => {
  if (includeExternal()) {
    try {
      await axios.post(
        'https://wtr-digital-analytics.ew.r.appspot.com/citrusad/order',
        transactionCompleteData,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
    } catch (error) {
      consoleError(error);
    }
  }
};
