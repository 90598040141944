import { useSelector } from 'react-redux';
import { getExperienceFragmentLocation } from 'redux/modules/experience-fragments/selectors';

interface UseExperienceFragmentTitleResult {
  title?: string;
  subtitle?: string;
}

/**
 * Retrieves the title and subtitle of a `Title Component` (located in the Waitrose Groceries Components in AEM)
 * @param experienceFragmentKey The key (path) of the fragment experience
 * @param location The `Location Name` of the `Experience Fragment Grid`
 * @returns The found `title` and `subtitle`, if any.
 */
export function useExperienceFragmentTitle(
  experienceFragmentKey: string,
  location = 'title',
): UseExperienceFragmentTitleResult {
  const fragment = useSelector((state: WtrState) =>
    getExperienceFragmentLocation(state, experienceFragmentKey, location),
  );

  const { title, subtitle } =
    fragment?.[0]?.childComponents?.find(component => component.nodeName === 'title') ?? {};

  return { title, subtitle };
}
