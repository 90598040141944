import { connect } from 'react-redux';

import { showContactAddressNotPresentNotification } from 'redux/modules/address/selectors/get-address-selectors';
import { thirdColumnMenu } from 'constants/cms-menus';
import {
  getMegaMenuHistory,
  getNavigationMenus,
  getPreviousActiveTitle,
} from 'redux/modules/mega-menu/selectors';

import closeMegaMenuSubcategory from 'redux/modules/mega-menu/actions/close-mega-menu-subcategory';
import openMegaMenuSubcategory from 'redux/modules/mega-menu/actions/open-mega-menu-subcategory';
import { navigateFromMegaMenu } from 'redux/modules/page/actions/navigate-from-mega-menu';

import SlideOutNav from './SlideOutNav';

export default connect(
  state => ({
    showContactAddressNotPresentNotification: showContactAddressNotPresentNotification(state),
    level: getMegaMenuHistory(state).length,
    menus: getNavigationMenus(state),
    previousTitle: getPreviousActiveTitle(state),
    specialistShopLinks: thirdColumnMenu,
  }),
  dispatch => ({
    closeSubcategory: () => dispatch(closeMegaMenuSubcategory()),
    navigateFromMegaMenu: (level, name, id) => dispatch(navigateFromMegaMenu(level, name, id)),
    openSubcategory: itemId => dispatch(openMegaMenuSubcategory(itemId)),
  }),
)(SlideOutNav);
