export const EXPERIENCE_FRAGMENT_SUBMIT = 'waitrose/experience_fragment/SUBMIT';
export const EXPERIENCE_FRAGMENT_SUBMIT_FAIL = 'waitrose/experience_fragment/FAILED';
export const EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS = 'waitrose/experience_fragment/SUCCESS';

// TODO: Need to create AemResponses types
export type ExperienceFragmentLocationData = {
  resourceType?: string;
  childComponents?: ExperienceFragmentLocationData;
  nodeName?: string;
  title?: string;
  subtitle?: string;
  [key: string]: unknown;
}[];

export type ExperienceFragment = {
  totalMatches: number;
  productsInResultset: number;
  searchTime: number;
  totalTime: number;
  metadata: Record<string, unknown>;
  locations: Record<string, ExperienceFragmentLocationData>;
  showPageTitle: boolean;
  pageTitle: {
    text: string;
  };
  addAllToBasket: boolean;
  status: number;
  header: Record<string, unknown>;
};

export type ExperienceFragments = {
  [key: string]: ExperienceFragment | undefined;
};

export type ExperienceFragmentActionType = {
  type:
    | typeof EXPERIENCE_FRAGMENT_SUBMIT
    | typeof EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS
    | typeof EXPERIENCE_FRAGMENT_SUBMIT_FAIL;
  experienceFragmentKey: string;
};
