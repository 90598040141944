import React from 'react';
import { bool, func, string } from 'prop-types';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';

const SignOutButton = ({
  isSignedOnAsCustomer,
  loggedIn,
  onBlur,
  onClick,
  onFocus,
  onLogoutUser,
  width,
}) =>
  loggedIn && !isSignedOnAsCustomer ? (
    <Button
      data-test="signOut"
      label="Sign out"
      onBlur={onBlur}
      onClick={() => {
        if (onClick) {
          onClick();
        }

        onLogoutUser();
      }}
      onFocus={onFocus}
      theme="secondary"
      type="button"
      width={width}
    />
  ) : null;

SignOutButton.propTypes = {
  isSignedOnAsCustomer: bool.isRequired,
  loggedIn: bool.isRequired,
  onBlur: func,
  onClick: func,
  onFocus: func,
  onLogoutUser: func.isRequired,
  width: string,
};

SignOutButton.defaultProps = {
  onBlur: null,
  onClick: null,
  onFocus: null,
  width: undefined,
};

SignOutButton.displayName = 'SignOutButton';

export default SignOutButton;
