import { getOrderProductLineNumbersByCustomerOrderId } from 'redux/modules/entities/selectors/customer-orders';
import { handleTimeoutError } from 'redux/modules/order/actions/errors';
import { fetchOrderProducts } from 'redux/modules/orders/actions/fetch-order-products';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { separateByChunks } from 'utils/array';

export const MAX = 100; // As dictated by the Unbxd max in the BE

export const fetchCustomerOrderProducts = customerOrderId => async (dispatch, getState) => {
  const state = getState();
  const lineNumbers = getOrderProductLineNumbersByCustomerOrderId(state, customerOrderId);
  const currentCustomerOrderId = getCustomerOrderId(state);

  if (lineNumbers.length > 0) {
    // getOrderWithRecommendations already returns the first products
    const arrays = separateByChunks(lineNumbers, MAX).splice(1);

    return Promise.all(
      arrays.map(arr =>
        dispatch(
          fetchOrderProducts(arr, {
            customerOrderId: currentCustomerOrderId,
          }),
        ),
      ),
    ).catch(error => {
      if (dispatch(handleTimeoutError(error))) return;

      throw error;
    });
  }
  return Promise.resolve();
};
