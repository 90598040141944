import update from 'immutability-helper';

import {
  GET_ORDER_LOADED,
  GET_ORDER_RECOMMENDATIONS_LOADED,
} from 'redux/modules/orders/actions/types';

const orders = (state = {}, action = {}) => {
  const { type } = action;

  switch (type) {
    case GET_ORDER_LOADED: {
      const { result: order = {} } = action;
      const { customerOrderId } = order;

      return update(state, { $merge: { [customerOrderId]: order } });
    }
    case GET_ORDER_RECOMMENDATIONS_LOADED: {
      const { result: { products, totalResults, customerOrderId } = {} } = action;

      return update(state, {
        $merge: {
          [customerOrderId]: {
            ...state[customerOrderId],
            recommendedProducts: { products, totalResults },
          },
        },
      });
    }
    default:
      return state;
  }
};

export default orders;
