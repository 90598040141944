import root from 'window-or-global';
import locator from 'utils/locator';
import urls from 'constants/urls';

import { callCustomerDataLayerEvent } from 'redux/modules/login/customer-datalayer-event';
import { dataLayer } from 'analytics/data-layer';

export const notifyLoginError = (message: string) => {
  dataLayer.push({
    event: 'login_error',
    error_message: message,
  });
};

export const notifyLoginSuccess = () => (_dispatch: WtrDispatch, getState: () => WtrState) => {
  const justLoggedIn = root.localStorage.getItem('isLogging');
  if (justLoggedIn === 'true' && locator.pathname !== urls.login) {
    root.localStorage.removeItem('isLogging');
    callCustomerDataLayerEvent(getState(), 'login');
  }
};
