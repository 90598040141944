import get from 'lodash/get';
import update from 'immutability-helper';
import { deepFreeze } from 'utils/deepFreeze';

import linkTypes from 'constants/link-types';
import {
  FAVOURITES_CATEGORIES_FULFILLED,
  FAVOURITES_PRODUCTS_FULFILLED,
  FAVOURITES_PRODUCTS_PENDING,
  FAVOURITES_PRODUCTS_REJECTED,
  FAVOURITES_SET_FILTERS,
  FAVOURITES_SET_SORT_ORDER,
  FAVOURITES_SUMMARY_FULFILLED,
  FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED,
  RECOMMENDATIONS_FAVOURITES_PRODUCTS_FULFILLED,
} from 'redux/modules/favourites/actions/types';

const initialState = deepFreeze({
  categoryIdFilter: undefined,
  error: null,
  forgotten: [],
  recommendations: [],
  links: [],
  loading: false,
  loadingMore: false,
  offersFilter: false,
  products: [],
  rootCategories: [],
  sortBy: undefined,
  summaryProducts: undefined,
  totalForgotten: null,
  totalRecommendations: null,
  totalResults: null,
  nRequestedProducts: 0,
});

const filterNonCategoryLinks = links =>
  links.filter(link => get(link, 'metaData.group') === linkTypes.CATEGORY);

export default function favourites(state = initialState, action = {}) {
  switch (action.type) {
    case FAVOURITES_CATEGORIES_FULFILLED:
      return {
        ...state,
        rootCategories: filterNonCategoryLinks(action.result.links),
      };
    case FAVOURITES_PRODUCTS_FULFILLED:
      return {
        ...state,
        links: action.result.links,
        loading: false,
        loadingMore: false,
        products: action.start
          ? update(state.products, { $push: action.result.products })
          : action.result.products,
        totalResults: action.result.totalResults,
      };
    case FAVOURITES_PRODUCTS_PENDING:
      return {
        ...state,
        error: null,
        loading: !action.start,
        loadingMore: !!action.start,
      };
    case FAVOURITES_PRODUCTS_REJECTED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loadingMore: false,
      };
    case FAVOURITES_SET_FILTERS: {
      const filters = action.payload;
      return {
        ...state,
        categoryIdFilter: filters.categoryId,
        offersFilter: filters.onOffer,
      };
    }
    case FAVOURITES_SET_SORT_ORDER: {
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };
    }
    case FAVOURITES_SUMMARY_FULFILLED: {
      return { ...state, summaryProducts: get(action, 'result.products', []) };
    }
    case FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        forgotten: action.start
          ? update(state.forgotten, { $push: action.result.products })
          : action.result.products,
        totalForgotten: action.result.totalResults,
        nRequestedProducts: action.start ? state.nRequestedProducts + action.size : action.size,
      };
    case RECOMMENDATIONS_FAVOURITES_PRODUCTS_FULFILLED:
      return {
        ...state,
        recommendations: action.result.products,
        totalRecommendations: action.result.totalResults,
      };
    default:
      return state;
  }
}
