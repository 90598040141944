import { deepFreeze } from 'utils/deepFreeze';

type Request =
  | {
      loaded: boolean;
    }
  | unknown;

interface LinkedMembership {
  brand: string;
  customerId: string;
  myJLId?: string;
}

interface LinkedMembershipResponse {
  hasLinkedMembership: boolean;
  linkedMemberships: LinkedMembership[];
}

export interface LinkedMembershipState {
  request: Request;
  status: LinkedMembershipResponse;
}

const initialState: Readonly<LinkedMembershipState> = deepFreeze({
  request: { loaded: false },
  status: {
    hasLinkedMembership: false,
    linkedMemberships: [],
  },
});

export default initialState;
