import { LOCATION_CHANGE } from 'redux-first-history';
import root from 'window-or-global';

// the point of this middleware is to add property to LOCATION CHANGE redux action when the store is being
// hydrated from SSR (instead of a client side navigation)
// and responsible to decorate the `router` store with previousPath attr

export default ({ getState }) =>
  next =>
  action => {
    if (action.type !== LOCATION_CHANGE) {
      return next(action);
    }

    const { router } = getState();
    const { location } = router || {};
    const { pathname, search = '' } = location || {};
    const previousPath = pathname ? `${pathname}${search}` : root.document?.referrer;
    const previousPaths = [...(router?.location?.previousPaths || []), pathname];

    if (location) {
      return next({
        ...action,
        payload: {
          ...action.payload,
          location: {
            ...action.payload.location,
            previousPath,
            previousPaths,
          },
        },
      });
    }

    return next({ ...action, payload: { ...action.payload, initialPageLoad: true } });
  };
