import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

const FAVOURITES_CATEGORIES_PENDING = 'waitrose/favourites/FAVOURITES_CATEGORIES_PENDING';
export const FAVOURITES_CATEGORIES_FULFILLED =
  'waitrose/favourites/FAVOURITES_CATEGORIES_FULFILLED';
const FAVOURITES_CATEGORIES_REJECTED = 'waitrose/favourites/FAVOURITES_CATEGORIES_REJECTED';

export const FAVOURITES_PRODUCTS_PENDING = 'waitrose/favourites/FAVOURITES_PRODUCTS_PENDING';
export const FAVOURITES_PRODUCTS_FULFILLED = 'waitrose/favourites/FAVOURITES_PRODUCTS_FULFILLED';
export const FAVOURITES_PRODUCTS_REJECTED = 'waitrose/favourites/FAVOURITES_PRODUCTS_REJECTED';

export const FAVOURITES_SUMMARY_PENDING = 'waitrose/favourites/FAVOURITES_SUMMARY_PENDING';
export const FAVOURITES_SUMMARY_FULFILLED = 'waitrose/favourites/FAVOURITES_SUMMARY_FULFILLED';
export const FAVOURITES_SUMMARY_REJECTED = 'waitrose/favourites/FAVOURITES_SUMMARY_REJECTED';

export const FAVOURITES_SET_FILTERS = 'waitrose/favourites/SET_FILTERS';
export const FAVOURITES_SET_SORT_ORDER = 'waitrose/favourites/FAVOURITES_SET_SORT_ORDER';

export const FAVOURITES_CATEGORY_FILTER_IMPRESSION =
  'waitrose/favourites/FAVOURITES_CATEGORY_FILTER_IMPRESSION';
export const FAVOURITES_OFFER_FILTER_IMPRESSION =
  'waitrose/favourites/FAVOURITES_OFFER_FILTER_IMPRESSION';
export const FAVOURITES_SORT_IMPRESSION = 'waitrose/favourites/FAVOURITES_SORT_IMPRESSION';

export const FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED =
  'waitrose/favourites/FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED';

export const RECOMMENDATIONS_FAVOURITES_PRODUCTS_FULFILLED =
  'waitrose/recommendations/favourites/RECOMMENDATIONS_PRODUCTS_FULFILLED';

export const FAVOURITES_SUMMARY_ACTIONS = [
  FAVOURITES_SUMMARY_PENDING,
  FAVOURITES_SUMMARY_FULFILLED,
  FAVOURITES_SUMMARY_REJECTED,
];

export const FAVOURITES_PRODUCTS_ACTIONS = [
  FAVOURITES_PRODUCTS_PENDING,
  FAVOURITES_PRODUCTS_FULFILLED,
  FAVOURITES_PRODUCTS_REJECTED,
];

export const FAVOURITES_CATEGORIES_ACTIONS = [
  FAVOURITES_CATEGORIES_PENDING,
  FAVOURITES_CATEGORIES_FULFILLED,
  FAVOURITES_CATEGORIES_REJECTED,
];

export const FORGOTTEN_FAVOURITES_PRODUCTS_ACTIONS = [
  FAVOURITES_PRODUCTS_PENDING,
  FORGOTTEN_FAVOURITES_PRODUCTS_FULFILLED,
  FAVOURITES_PRODUCTS_REJECTED,
];

export const GET_FAVOURITES = apiPromiseTypes('favourites-experience', 'get');
