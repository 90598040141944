import React, { MouseEvent } from 'react';

import { BREAKPOINTS } from 'constants/grid';
import { useClientSideMediaQuery } from 'hooks/use-client-side-media-query';
import { pushMenuClick } from 'utils/gtm';

type MenuExperienceFragmentClickHandlerProps = {
  trackingPrefix: string;
  children: JSX.Element;
  onClose: (event: MouseEvent) => void;
};

const MenuExperienceFragmentClickHandler = ({
  trackingPrefix,
  children,
  onClose,
}: MenuExperienceFragmentClickHandlerProps) => {
  const isSmallScreen = useClientSideMediaQuery({ maxWidth: BREAKPOINTS.lg });

  const handleClick = (event: MouseEvent) => {
    const { target } = event;
    let element: HTMLAnchorElement | null = null;

    if (target instanceof HTMLAnchorElement) {
      element = target;
    } else if (target instanceof HTMLElement) {
      element = target.closest('a');
    }

    if (element) {
      const { href, textContent } = element;

      if (href) {
        const trackingLabel = textContent || href;

        pushMenuClick(`${trackingPrefix} | ${trackingLabel}`, {
          type: isSmallScreen ? 'mobile' : 'desktop',
          contentOrigin: 'aem',
        });
        onClose(event);
      }
    }
  };

  return (
    // This just listens to bubbled events and is not actually interactive
    <div role="none" onClick={handleClick}>
      {children}
    </div>
  );
};

export default MenuExperienceFragmentClickHandler;
