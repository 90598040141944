import root from 'window-or-global';
import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { createReduxHistoryContext } from 'redux-first-history';

import env from 'env/env';
import { dataLayer } from 'analytics/data-layer';
import actionsToTrack from 'redux/middleware/analytics/actions-to-track';
import analyticsMiddleware from 'redux/middleware/analytics';
import apiPromise from 'redux/middleware/api-promise';
import customRouterMiddleware from 'redux/middleware/router';
import ignoreInitialLocationEvent from 'redux/middleware/router/ignore-initial-location-event';
import customDimensionsMiddleware from 'redux/middleware/custom-dimensions';
import impactMiddleware from 'redux/middleware/impact';
import { actionHandlers } from 'redux/middleware/impact/action-handlers';
import monetateMiddleware from 'redux/middleware/monetate';
import processResult from 'redux/middleware/process-result';
import trolleyQueue from 'redux/middleware/trolley-queue';
import dimensions from 'redux/middleware/custom-dimensions/dimensions';
import delayedActionMiddleware from 'redux/middleware/delayed-action';
import reducer from './reducer';

export const createStoreAndHistory = (clientHistory, preloadState) => {
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: clientHistory,
    savePreviousLocations: 50,
  });

  const middlewares = [
    reduxThunk,
    trolleyQueue(),
    monetateMiddleware,
    apiPromise,
    processResult,
    routerMiddleware,
    customRouterMiddleware,
    analyticsMiddleware(dataLayer, actionsToTrack),
    ignoreInitialLocationEvent,
    customDimensionsMiddleware(dataLayer, dimensions),
    delayedActionMiddleware,
    impactMiddleware(actionHandlers),
  ].filter(Boolean);

  const composeEnhancers =
    // eslint-disable-next-line no-underscore-dangle
    env.development && root.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // eslint-disable-next-line no-underscore-dangle
        root.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: 150 })
      : compose;

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const store = reduxCreateStore(reducer(routerReducer), preloadState, enhancer);
  const history = createReduxHistory(store);

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      store.replaceReducer(require('./reducer')); // eslint-disable-line global-require, @typescript-eslint/no-require-imports
    });
  }

  return { store, history };
};

export const createStore = (clientHistory, preloadState) =>
  createStoreAndHistory(clientHistory, preloadState).store;
