import get from 'lodash/get';

export const getBreadcrumbs = state => get(state, 'cmsPage.breadcrumbs', []);

export const getComponents = state => get(state, 'cmsPage.locations.root');

export const getError = state => get(state, 'cmsPage.error');

export const getContentUrl = state => state?.cmsPage?.contentUrl;

export const getLoading = state => get(state, 'cmsPage.loading');

export const getMetaData = state => {
  const metaData = state.cmsPage && state.cmsPage.metaData;
  return {
    ...metaData,
    keywords:
      metaData && metaData.keywords && metaData.keywords.length
        ? metaData.keywords.join(',')
        : null,
  };
};

export const getPageTitle = state => get(state, 'cmsPage.pageTitle', {});

export const getShowPageTitle = state => get(state, 'cmsPage.showPageTitle', true);

export const getLocationHash = state => get(state, 'router.location.hash', null);

export const getPageNotFound = state => state.cmsPage.pageNotFound || false;
