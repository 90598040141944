import root from 'window-or-global';
import { asyncLocalStorage } from 'server/utils/async-local-storage';

const serverMonetateQ = {};

if (__SERVER__) {
  serverMonetateQ.push = (...args) => {
    const requestContext = asyncLocalStorage.getStore();
    const monetateEvents = requestContext.get('monetateEvents') || [];
    monetateEvents.push(...args);
    requestContext.set('monetateEvents', monetateEvents);
  };
}

const idleUntilUrgentClientDataLayer = {
  buffer: [],
  hasBufferFlushOnVisibilityHiddenBeenSetup: false,
  sendScheduled: false,
  flushBuffer() {
    this.buffer.forEach(event => {
      root.monetateQ.push(event);
    });

    this.buffer = [];
  },
  performSend() {
    if (!this.buffer.length) {
      return;
    }

    const event = this.buffer.shift();
    root.monetateQ.push(event);

    this.sendScheduled = false;

    if (this.buffer.length > 0) {
      this.schedulePendingEvents();
    }
  },
  push(...args) {
    if (!root.monetateQ) {
      return;
    }

    if (!this.hasBufferFlushOnVisibilityHiddenBeenSetup) {
      this.setupBufferFlushOnVisibilityHidden();
    }

    this.buffer.push(...args);

    if (!this.sendScheduled) {
      this.schedulePendingEvents();
    }
  },
  schedulePendingEvents() {
    this.sendScheduled = true;

    if (root.requestIdleCallback) {
      root.requestIdleCallback(() => {
        this.performSend();
      });
    } else {
      setTimeout(() => {
        this.performSend();
      }, 0);
    }
  },
  setupBufferFlushOnVisibilityHidden() {
    this.hasBufferFlushOnVisibilityHiddenBeenSetup = true;

    root.addEventListener(
      'visibilitychange',
      () => {
        if (root.document.visibilityState === 'hidden') {
          this.flushBuffer();
        }
      },
      true,
    );
  },
};

const clientMonetateQ = {
  push: (...args) => idleUntilUrgentClientDataLayer.push(...args),
};

export const monetateQ = __SERVER__ ? serverMonetateQ : clientMonetateQ;
