import { FullWtrState, NamespaceKey } from 'redux/store';

export function getStateAtNamespaceKey<T extends NamespaceKey>(
  state: WtrState,
  key: T,
): FullWtrState[T] {
  const namespaceState = state[key];
  if (!namespaceState) {
    throw new Error(`Attempted to access state for an unregistered namespace at key ${key}`);
  }

  return namespaceState;
}
