import { createSelector } from 'reselect';

export const getAddAllToBasket = state => !!state?.content?.addAllToBasket ?? false;

export const getLocations = ({ content: { locations = {} } = {} } = {}) => locations;
export const getLocationsLoading = ({ content: { locationsLoaded } = {} } = {}) => !locationsLoaded;

const getLocationName = (_state, name) => name;

export const getLocationByName = createSelector(
  [getLocations, getLocationName],
  (locations = {}, name) => locations[name] || [],
);

export const getLocationsBody = state => getLocationByName(state, 'body');
export const getLocationsHeader = state => getLocationByName(state, 'header');
export const getLocationsMasthead = state => getLocationByName(state, 'masthead');
export const getLocationsSEOContent = state => getLocationByName(state, 'seoContent');
export const getLocationsRoot = state => getLocationByName(state, 'root');

export const hasContentByName = createSelector([getLocationByName], content =>
  content ? content.length > 0 : false,
);

export const hasHeaderContent = state => hasContentByName(state, 'header');
export const hasLocationsMasthead = state => hasContentByName(state, 'masthead');
export const hasLocationsSEOContent = state => hasContentByName(state, 'seoContent');
