import { arrayOf, bool, func, object, number, oneOfType, shape, string } from 'prop-types';
import React, { useCallback } from 'react';
import { useClientOnlyLayoutEffect } from 'hooks/use-client-only-layout-effect';
import classNames from 'classnames/bind';
import { pushMenuClickWithLevels } from 'utils/gtm';
import { useNavigate } from 'react-router-dom';

import { refType } from 'constants/types/ref';

import SubCategory from 'components/MegaMenu/MenuLink/SubCategory';
import MenuLink from 'components/MegaMenu/MenuLink';
import NavigationProvider from 'components/MegaMenu/NavigationProvider';
import { yieldToMain } from 'utils/yield-to-main';

import styles from './MenuLinksList.scss';

const MenuLinksList = ({
  activeChildId,
  category,
  keyboardLevel,
  handleClickToClose,
  level,
  menuMaxLength,
  menuRoot,
  menus,
  navigateFromMegaMenu,
  navigationRef,
  offersPath,
  parentId,
  path,
  relationship,
  setKeyboardLevel,
  setMegaMenu,
  title,
}) => {
  const navigate = useNavigate();
  const getItemInfo = useCallback(
    id => {
      const { hasDescendants, name } = category.categories?.[id] ?? {};
      const isParent = hasDescendants && level + 1 < menuMaxLength;

      return { isParent, name };
    },
    [category, level, menuMaxLength],
  );

  const handleKeyboardLeft = useCallback(() => {
    if (level) {
      const previousLevel = level - 1;
      // close and fall back to previous level
      setMegaMenu(previousLevel, parentId);

      // ensure keyboard focus side-effects occur
      setKeyboardLevel(previousLevel);
    }
  }, [parentId, level, setKeyboardLevel, setMegaMenu]);

  const handleKeyboardRight = useCallback(
    id => {
      const { isParent, name } = getItemInfo(id);
      const nextLevel = level + 1;

      if (!isParent) return;

      // prevent further navigation during transitions to avoid circular effects
      navigationRef.current.unfocus();

      // open next level
      setMegaMenu(nextLevel, id, name);

      // ensure keyboard focus side-effects occur
      setKeyboardLevel(nextLevel);
    },
    [getItemInfo, level, navigationRef, setKeyboardLevel, setMegaMenu],
  );

  const handleClickMenuItem = useCallback(
    async (event, id, url, viewAllName = undefined) => {
      const { isParent, name } = getItemInfo(id);

      // Prevent focus side-effects intended for keyboard navigation
      setKeyboardLevel(null);

      event.preventDefault();
      if (isParent) {
        // prevent link navigation and open next menu level
        setMegaMenu(level + 1, id, name);
      } else {
        const vAllName = name || viewAllName;
        navigateFromMegaMenu(level + 1, vAllName, id);
        handleClickToClose(event);

        await yieldToMain();

        navigate(url);
        pushMenuClickWithLevels({ level, menus, name: event.target.title });
      }
    },
    [
      getItemInfo,
      level,
      menus,
      handleClickToClose,
      navigateFromMegaMenu,
      setKeyboardLevel,
      setMegaMenu,
      navigate,
    ],
  );

  useClientOnlyLayoutEffect(() => {
    // refocus the currently active level, for left/right keyboard navigation
    if (keyboardLevel === level) {
      navigationRef.current.refocus(0);
    }
  }, [keyboardLevel, level, navigationRef]);

  if (!title) return null;

  const componentClass = classNames(styles[relationship], styles[`level${level}`], {
    [styles.active]: relationship === 'ancestor' || level > 0,
  });
  const menuLinks = category?.categoryIds ?? [];
  const offerLevels = 2;
  const offerStartLevel = menuRoot === 'Shop' ? 1 : 0;
  const showOffers = level >= offerStartLevel && level < offerStartLevel + offerLevels;
  const offersLink = (
    <li className={styles.offers} role="menuitem">
      <MenuLink
        label={`${title} <strong>OFFERS</strong>`}
        path={path}
        shortName="offers"
        title={`${title} Offers`}
        url={offersPath}
        onClick={(event, id, url) => handleClickMenuItem(event, id, url, `${title} Offers`)}
      />
    </li>
  );

  return (
    <NavigationProvider
      onEnterKey={handleKeyboardRight}
      onLeftKey={handleKeyboardLeft}
      onRightKey={handleKeyboardRight}
      ref={navigationRef}
    >
      <div
        aria-expanded={relationship !== 'descendant'}
        aria-hidden={relationship === 'descendant'}
        className={componentClass}
        data-testid="menu-links-list"
      >
        <ul
          data-test={`mega-menu-${level}-list`}
          className={styles.menuLinks}
          aria-label={`Use up, down, left and right arrows to browse ${title}.`}
          role="menu"
        >
          {/* View all ... link */}
          <li className={styles.all} role="menuitem">
            <MenuLink
              title={`View all ${title}`}
              path={path}
              shortName="all"
              url={path}
              onClick={(event, id, url) => handleClickMenuItem(event, id, url, `View all ${title}`)}
            />
          </li>
          {showOffers && offersLink}
          {/* Taxonomy menu items */}
          {menuLinks.map(id => (
            <li key={`${id}`} role="menuitem">
              <SubCategory
                categoryId={id}
                isActive={activeChildId === id}
                level={level}
                onClick={handleClickMenuItem}
                path={path}
              />
            </li>
          ))}
        </ul>
      </div>
    </NavigationProvider>
  );
};

MenuLinksList.displayName = 'MenuLinksList';

MenuLinksList.propTypes = {
  activeChildId: string,
  category: shape({
    categoryIds: arrayOf(string),
    categories: shape({
      id: string,
      name: string,
      categoryIds: arrayOf(string),
      hasDescendants: bool,
    }),
  }),
  handleClickToClose: func.isRequired,
  keyboardLevel: number,
  level: number.isRequired,
  menuMaxLength: number.isRequired,
  menuRoot: string,
  menus: arrayOf(
    shape({
      id: string,
    }),
  ),
  navigateFromMegaMenu: func.isRequired,
  navigationRef: refType.isRequired,
  offersPath: oneOfType([object, string]),
  parentId: string,
  path: string,
  relationship: string,
  setKeyboardLevel: func.isRequired,
  setMegaMenu: func.isRequired,
  title: string,
};

MenuLinksList.defaultProps = {
  activeChildId: undefined,
  category: {},
  keyboardLevel: null,
  menuRoot: undefined,
  menus: [],
  offersPath: undefined,
  parentId: undefined,
  path: undefined,
  relationship: undefined,
  title: undefined,
};

export default MenuLinksList;
