import React from 'react';
import { TradingCell } from '@johnlewispartnership/wtr-content-component-library';

import { usePromoImpression } from 'hooks/use-promo-impression';

const TradingCellWrapper = (props: any) => {
  const { componentId, analytics, position, products } = props;
  const observerRef = usePromoImpression({ componentId, analytics, position, products });

  return <TradingCell ref={observerRef} {...props} />;
};

export default TradingCellWrapper;
