import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/en-gb';

const TIMEZONE = 'Europe/London';
export const LOCALE = 'en-gb';

// Formats from https://day.js.org/docs/en/display/format
export const FORMAT = {
  HOUR: 'h',
  HOUR_MINUTE: 'h:m',
  HOUR_AMPM: 'ha',
  PAD_HOUR_AMPM: 'hha',
  HOUR_AMPM_UPPER: 'hA',
  HOUR_MINUTE_AMPM: 'h:mma',
  HOUR24_MINUTE: 'HH:mm',
  ISO8601_TIME: 'HH:mm:ss',
  SHORT_DAY: 'ddd DD',
  SHORT_1DAY_MONTH: 'D MMM',
  SHORT_DAY_MONTH: 'ddd D MMM',
  SHORT_2DAY_MONTH: 'ddd DD MMM',
  SHORT_DAY_MONTH_YEAR: 'DD MMM YYYY',
  SHORT_DAY_MONTH_TIME: 'ddd D MMM h:mma',
  LONG_DAY_MONTH: 'dddd D MMMM',
  LONG_DAY_ORDINAL_MONTH: 'dddd Do MMMM',
  LONG_DAY_ORDINAL_MONTH_YEAR: 'dddd Do MMMM YYYY',
  LONG_1DAY_MONTH: 'D MMMM',
  LONG_1DAY_ORDINAL_MONTH: 'Do MMMM',
  LONG_DAY_MONTH_YEAR: 'D MMMM YYYY',
  LONG_2DAY_MONTH_YEAR: 'DD MMMM YYYY',
  LONG_2DAY_MONTH: 'dddd DD MMMM',
  LONG_DAY_MONTH_HOUR: 'dddd D MMMM ha',
  ISO8601_DATE: 'YYYY-MM-DD',
  DAY_MONTH: 'DD MMMM',
  DAY_MONTH_YEAR: 'DD-MM-YYYY',
  DAY_MONTH_2YEAR: 'DD-MM-YY',
  DAY_OF_WEEK: 'dddd',
  YEAR: 'YYYY',
  DAY_MONTH_YEAR_SLASH: 'DD/MM/YYYY',
  LONG_MONTH_DAY: 'MMMM DD',
} as const;

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekday);

dayjs.locale(LOCALE);
dayjs.tz.setDefault(TIMEZONE);

const invalidDate = () => new Date('');

export { dayjs };

// Functions that replicate the date-fns

// CHANGES - dayjs does not support parsing:
// * two digit centuries
// * week of year
// * week dates
// * ordinal dates
// * 24:00 as midnight, it rolls over to the start of the next day
// * extended century representation
// * extended year representation
// * float hours or minutes
// * two digit timezone offsets
export function parse(date?: dayjs.ConfigType): Date {
  if (!date) {
    return invalidDate();
  }
  return dayjs(date).toDate();
}

export function isValid(date?: dayjs.ConfigType): boolean {
  if (!date) {
    return false;
  }
  return dayjs(date).isValid();
}

export function format(date?: dayjs.ConfigType, template?: string): string {
  return dayjs(date || invalidDate()).format(template);
}

export function startOfDay(date: dayjs.ConfigType): Date {
  if (!date) {
    return invalidDate();
  }
  return dayjs(date).startOf('day').toDate();
}

export function endOfDay(date: dayjs.ConfigType): Date {
  if (!date) {
    return invalidDate();
  }
  return dayjs(date).endOf('day').toDate();
}

export function addDays(date: dayjs.ConfigType, value: number): Date {
  if (!date) {
    return invalidDate();
  }
  return dayjs(date).add(value, 'day').toDate();
}

export function addYears(date: dayjs.ConfigType, value: number): Date {
  if (!date) {
    return invalidDate();
  }
  return dayjs(date).add(value, 'year').toDate();
}

export function isAfter(date: dayjs.ConfigType, dateToCompare: dayjs.ConfigType): boolean {
  if (!date || !dateToCompare) {
    return false;
  }
  return dayjs(dayjs(date)).isAfter(dayjs(dateToCompare));
}

export function isBefore(date: dayjs.ConfigType, dateToCompare: dayjs.ConfigType): boolean {
  if (!date || !dateToCompare) {
    return false;
  }
  return dayjs(date).isBefore(dayjs(dateToCompare));
}

export function isSameDay(dateLeft: dayjs.ConfigType, dateRight: dayjs.ConfigType): boolean {
  if (!dateLeft || !dateRight) {
    return false;
  }
  return dayjs(dateLeft).isSame(dayjs(dateRight), 'day');
}

export function isWithinRange(
  date: dayjs.ConfigType,
  startDate: dayjs.ConfigType,
  endDate: dayjs.ConfigType,
): boolean {
  if (!date || !startDate || !endDate) {
    return false;
  }
  return dayjs(date).isBetween(startDate, endDate, undefined, '[]');
}

export function max(...dates: dayjs.ConfigType[]): Date {
  const timestamps = dates.filter(d => !!d).map(d => dayjs(d).valueOf());
  const latestTimestamp = Math.max(...timestamps);
  return new Date(latestTimestamp);
}

export function min(...dates: dayjs.ConfigType[]): Date {
  const timestamps = dates.filter(d => !!d).map(d => dayjs(d).valueOf());
  const latestTimestamp = Math.min(...timestamps);
  return new Date(latestTimestamp);
}

export function differenceInDays(dateLeft: dayjs.ConfigType, dateRight: dayjs.ConfigType): number {
  if (!dateLeft || !dateRight) {
    return NaN;
  }
  return dayjs(dateLeft).diff(dayjs(dateRight), 'day');
}

export function differenceInHours(dateLeft: dayjs.ConfigType, dateRight: dayjs.ConfigType): number {
  if (!dateLeft || !dateRight) {
    return NaN;
  }
  return dayjs(dateLeft).diff(dayjs(dateRight), 'hour');
}
