import { GET } from '../actions/types';

const getAutoLinkedMembershipSuccess = (state, action) => {
  const { hasLinkedMembership, linkedMemberships } = action.result;

  return {
    ...state,
    status: { hasLinkedMembership, linkedMemberships },
    request: {
      ...state.request,
      loaded: true,
    },
  };
};

export default map => {
  map.set(GET.success, getAutoLinkedMembershipSuccess);
  return map;
};
