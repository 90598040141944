import { connect } from 'react-redux';

import {
  getMegaMenuActiveLevel,
  getMegaMenuMaxLength,
  getMegaMenuMenus,
} from 'redux/modules/page/selectors/mega-menu';
import { setMegaMenu } from 'redux/modules/page/actions/set-mega-menu';
import { getIsTaxonomyLoading } from 'redux/modules/taxonomy/selectors';

import MegaMenu from './Menus';

export default connect(
  state => ({
    activeLevel: getMegaMenuActiveLevel(state),
    loading: getIsTaxonomyLoading(state),
    maxMenus: getMegaMenuMaxLength(state),
    menus: getMegaMenuMenus(state),
  }),
  {
    setMegaMenu,
  },
)(MegaMenu);
