import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import SkipLink from 'components/SkipLink';

import { getIsSupportedBrowser } from 'redux/modules/browserInfo/selectors';
import { useExperiments } from 'components/Experiment/useExperiments';

import ContentLocation from 'components/ContentLocation';
import HomePageHeader from 'components/HomePage/Header';
import SiteHeader from 'components/SiteHeader';
import { HOME_PAGE_HEADER } from 'constants/monetateExperiments';
import ReturnToAdmin from 'components/ReturnToAdmin';
import SiteFooter from 'components/Footer/SiteFooter';

import styles from './HomePage.scss';

const HomePage = ({ isSupportedBrowser }) => {
  const { getDecisionById } = useExperiments();

  const displayGroceryHeader =
    getDecisionById(HOME_PAGE_HEADER.experiment).variant ===
    HOME_PAGE_HEADER.variant.displayGroceryHeader;

  return (
    <Fragment>
      <SkipLink destination="#main">Skip to main content</SkipLink>
      <ReturnToAdmin />
      {displayGroceryHeader ? (
        <SiteHeader />
      ) : (
        <HomePageHeader isSupportedBrowser={isSupportedBrowser} />
      )}
      <main id="main">
        <div className={styles.contentBackgroundColour}>
          <ContentLocation name="masthead" />
        </div>
        <div
          data-testid="content-wrapper"
          className={classNames(styles.body, {
            [styles.hideShopWindows]: displayGroceryHeader,
          })}
        >
          <ContentLocation name="header" />
          <ContentLocation name="body" />
        </div>
      </main>
      <SiteFooter />
    </Fragment>
  );
};

HomePage.displayName = 'HomePage';

HomePage.propTypes = {
  isSupportedBrowser: propTypes.bool.isRequired,
};

export default connect(state => ({
  isSupportedBrowser: getIsSupportedBrowser(state),
}))(HomePage);
