import React from 'react';

import { thirdColumnMenu } from 'constants/cms-menus';
import { MIDDLE_MEGA_MENU } from 'constants/experience-fragments';
import ExperienceFragment from 'components/ExperienceFragment';
import MenuExperienceFragmentClickHandler from 'components/ExperienceFragment/MenuExperienceFragmentClickHandler';
import CMSMenuLinksList from '../CMSMenuLinksList/CMSMenuLinksList';
import styles from './CMSMenuPanel.scss';

const CMSMenuPanel = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <nav className={styles.column2}>
        <MenuExperienceFragmentClickHandler trackingPrefix="MiddleMegaMenu" onClose={onClose}>
          <ExperienceFragment
            experienceFragmentKey={MIDDLE_MEGA_MENU.key}
            locationName={MIDDLE_MEGA_MENU.location}
          />
        </MenuExperienceFragmentClickHandler>
      </nav>
      <nav className={styles.column3}>
        <CMSMenuLinksList menus={thirdColumnMenu} />
      </nav>
    </>
  );
};

CMSMenuPanel.displayName = 'CMSMenuPanel';

export default CMSMenuPanel;
