import React from 'react';
import { func, node, oneOf, string } from 'prop-types';

import urls from 'constants/urls';
import { filterSearchQuery } from 'utils/query-string-params';

import AnchorLink from 'components/AnchorLink';

const GoToTrolley = ({ children, className, id, linkStyle, onClick, originComponent, title }) => {
  const anchorProps = {
    'aria-label': title,
    className,
    displayAsButton: linkStyle === 'button' ? { secondary: true } : false,
    id,
    onClick,
    title,
  };

  return (
    <AnchorLink
      {...anchorProps}
      data-testid="header-trolley-link"
      data-actiontype="redirect"
      data-origincomponent={originComponent}
      data-shortdescription="Goes to trolley details"
      to={{
        pathname: urls.trolleyPage,
        search: filterSearchQuery('featureflag'),
      }}
    >
      {children}
    </AnchorLink>
  );
};

GoToTrolley.propTypes = {
  children: node.isRequired,
  className: string,
  id: string,
  linkStyle: oneOf(['button', 'text']),
  originComponent: string,
  onClick: func,
  title: string,
};

GoToTrolley.defaultProps = {
  className: '',
  id: null,
  linkStyle: 'text',
  onClick: () => {},
  originComponent: null,
  title: 'Go to your shopping trolley',
};

export default GoToTrolley;
