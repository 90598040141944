import { createSelector } from 'reselect';

import addressAsString from 'utils/address-as-string';

import amendingOrder from 'redux/modules/trolley/selectors/amending-order';
import { getCustomerOrderById } from 'redux/modules/entities/selectors/customer-orders';

const getSlotByOrderId = createSelector(
  getCustomerOrderById,
  ({ slots = [] } = {}) => slots.find(s => s.status === 'CONFIRMED') || slots[0],
);

export const getDeliveryAddress = createSelector([getSlotByOrderId], ({ deliveryAddress } = {}) =>
  addressAsString(deliveryAddress ?? undefined),
);

export const getBranchAddress = createSelector([getSlotByOrderId], ({ branchAddress } = {}) =>
  addressAsString(branchAddress ?? undefined),
);

const getCustomerOrderLinks = createSelector(getCustomerOrderById, ({ links = [] } = {}) => links);

export const hasChangeableSlot = createSelector(getCustomerOrderLinks, links =>
  links.some(link => link.rel === 'init-changeslot'),
);

export const isAmendable = createSelector(getCustomerOrderLinks, links =>
  links.some(link => link.rel === 'init-amend'),
);

export const isCancellable = createSelector(getCustomerOrderLinks, links =>
  links.some(link => link.rel === 'cancel'),
);

export const isNotCancellable = createSelector(
  [getCustomerOrderById, amendingOrder],
  ({ links = [] } = {}, amending) => !links.some(link => link.rel === 'cancel') || amending,
);

export const getAmendOrderCutOff = createSelector(
  getSlotByOrderId,
  ({ amendOrderCutoffDateTime } = {}) => amendOrderCutoffDateTime,
);

export const getSlotStartTime = createSelector(
  getSlotByOrderId,
  ({ startDateTime } = {}) => startDateTime,
);

export const getSlotEndTime = createSelector(
  getSlotByOrderId,
  ({ endDateTime } = {}) => endDateTime,
);

export const getSlotType = createSelector([getSlotByOrderId], ({ type } = {}) => type);

const getActualTotal = createSelector(
  getCustomerOrderById,
  ({ totals } = {}) => totals?.actual?.paid?.amount,
);

const getEstimatedTotal = createSelector(getCustomerOrderById, ({ totals } = {}) =>
  totals?.estimated?.toPay?.amount != null
    ? totals?.estimated?.toPay?.amount
    : totals?.estimated?.totalPrice?.amount,
);

export const getOrderTotal = createSelector(
  [getActualTotal, getEstimatedTotal],
  (actualTotal, estimatedTotal) => (actualTotal != null ? actualTotal : estimatedTotal),
);

export const getItemCount = createSelector(
  getCustomerOrderById,
  ({ orderLines = [] } = {}) => orderLines.length,
);

export const getNumberOfItems = createSelector(
  getCustomerOrderById,
  ({ numberOfItems = 0 } = {}) => numberOfItems,
);

export const getStatus = createSelector(
  getCustomerOrderById,
  /**
   * @returns {import('constants/orderStatus').OrderStatus}
   */
  ({ status } = {}) => status,
);
