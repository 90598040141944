import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getMetaData, getPageTitle } from 'redux/modules/cms-page/selectors';
import { useSelector } from 'react-redux';
import plannerStyles from './index.scss';
import ContentLocation from '../../ContentLocation';

const PrebuiltPlanner = () => {
  const pageTitle = useSelector(getPageTitle);
  const meta = useSelector(getMetaData);

  const metaFields = [{ name: 'robots', content: meta?.turnOffIndexing ? 'noindex' : 'index' }];
  if (meta?.description) {
    metaFields.push({ name: 'description', content: meta.description });
  }

  if (meta?.keywords) {
    metaFields.push({ name: 'keywords', content: meta.keywords });
  }

  return (
    <>
      <Helmet title={pageTitle?.display ? pageTitle?.text : 'Meal plan'} meta={metaFields} />
      <div className={plannerStyles.content}>
        <ContentLocation name="root" />
      </div>
    </>
  );
};

export default PrebuiltPlanner;
