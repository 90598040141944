import createReducer from 'redux/createReducer';
import requestSlotDates from 'redux/modules/slot-dates/reducers/request-slot-dates';
import slotDatesReceived from 'redux/modules/slot-dates/reducers/slot-dates-received';
import slotDatesRequestFailed from 'redux/modules/slot-dates/reducers/slot-dates-request-failed';
import campaignsRequest from 'redux/modules/slot-dates/reducers/campaigns-received';
import setCampaignDatesAsSlotDates from 'redux/modules/slot-dates/reducers/set-campaign-dates-as-slot-dates';

import {
  CAMPAIGNS_REQUEST_ACTIONS,
  SET_CAMPAIGN_DATES_AS_SLOT_DATES,
  SLOT_DATES_REQUEST_ACTIONS,
} from 'redux/modules/slot-dates/actions/types';

const initialState = {
  loading: false,
  dates: [],
  campaigns: {},
  error: null,
  showPriorityOnlyMessage: false,
  hasCampaignDatesOnly: false,
  times: [],
};

const actionTypeReducerMap = [
  [SLOT_DATES_REQUEST_ACTIONS.request, requestSlotDates],
  [SLOT_DATES_REQUEST_ACTIONS.success, slotDatesReceived],
  [SLOT_DATES_REQUEST_ACTIONS.failure, slotDatesRequestFailed],
  [CAMPAIGNS_REQUEST_ACTIONS.success, campaignsRequest],
  [SET_CAMPAIGN_DATES_AS_SLOT_DATES, setCampaignDatesAsSlotDates],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
