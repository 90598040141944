import { createSelector } from 'reselect';

const getPage = state => state.page;

export const getMegaMenu = createSelector(getPage, ({ megaMenu = {} } = {}) => megaMenu);

export const getMegaMenuActiveLevel = createSelector(getMegaMenu, ({ activeLevel }) => activeLevel);

export const getMegaMenuMaxLength = createSelector(
  getMegaMenu,
  ({ maxLength = null }) => maxLength,
);

export const getMegaMenuRoot = createSelector(getMegaMenu, ({ menuRoot = null }) => menuRoot);

export const getMegaMenuMenus = createSelector(getMegaMenu, ({ menus = [] }) => menus);
