import orderWithRecommendations from 'api/definitions/order/orderWithRecommendations';
import {
  GET_ORDER_FAILED,
  GET_ORDER_LOADED,
  GET_ORDER_LOADING,
  GET_ORDER_PRODUCTS_LOADED,
  GET_ORDER_RECOMMENDATIONS_LOADED,
} from 'redux/modules/orders/actions/types';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { LEGACY_PRODUCTS_SCHEMA } from 'redux/schemas';
import { TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT } from 'redux/transforms';
import { MAX } from '../../orders/actions/fetch-customer-order-products';
import { handleTimeoutError } from './errors';

const getOrder =
  ({ customerOrderId, handleError, recommendationsSize }) =>
  async (dispatch, getState) => {
    const trolleyId = getCustomerOrderId(getState());
    dispatch({
      type: GET_ORDER_LOADING,
      withProducts: true,
    });
    return orderWithRecommendations
      .post({
        orderId: customerOrderId,
        recommendationsSize,
        productsSize: MAX,
        trolleyId,
      })({ dispatch, getState })
      .then(response => {
        if (!response) {
          dispatch({
            error: 'Graph returned an error',
            type: GET_ORDER_FAILED,
          });
        } else {
          const { data, errors } = response;
          const failures = data?.getOrderWithRecommendations?.failures || [];
          if (errors || !data?.getOrderWithRecommendations || failures.length > 0) {
            if (handleError) handleError((errors || failures)[0]);
            dispatch({
              error: 'Graph returned an error',
              type: GET_ORDER_FAILED,
            });
          } else {
            dispatch({
              type: GET_ORDER_LOADED,
              result: {
                ...data.getOrderWithRecommendations.order,
              },
            });

            if (data.getOrderWithRecommendations.order.products) {
              dispatch({
                type: GET_ORDER_PRODUCTS_LOADED,
                result: {
                  products: data.getOrderWithRecommendations.order.products,
                },
                schema: LEGACY_PRODUCTS_SCHEMA,
                transform: TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT,
              });
            }
            if (!recommendationsSize) return;
            dispatch({
              type: GET_ORDER_RECOMMENDATIONS_LOADED,
              result: {
                customerOrderId,
                ...(data.getOrderWithRecommendations.recommendedProducts.products
                  ? data.getOrderWithRecommendations.recommendedProducts
                  : { products: [], totalResults: 0 }),
              },
              schema: LEGACY_PRODUCTS_SCHEMA,
              transform: TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT,
            });
          }
        }
      })
      .catch(error => {
        if (handleTimeoutError(error)(dispatch)) {
          return;
        }
        if (handleError) handleError(error);

        dispatch({
          error: 'Get Order call to graph failed',
          type: GET_ORDER_FAILED,
        });
      });
  };

export default getOrder;
